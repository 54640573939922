/* src/App.css */

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #ffffff; /* White background */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

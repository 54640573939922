/* src/Home.css */

/* Reset and body styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  background: #ffffff; /* Change background to white */
  color: #333;
}

/* Home container */
.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.header {
  background-color: #ffffff; /* Change header background to white */
  color: #1E90FF; /* Change text color to blue */
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.header-text h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

/* Intro section */
.intro {
  text-align: center;
  margin: 40px 20px;
}

.intro p {
  font-size: 1.2em;
  color: #666;
}

/* Features section */
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Spacing between tiles */
  margin: 20px;
}

.feature {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 280px;
  text-align: center;
  transition: transform 0.3s;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 60px;
  color: #1E90FF; /* Change icon color to blue */
  margin-bottom: 20px;
}

.feature h3 {
  font-size: 1.6em;
  margin-bottom: 15px;
  color: #1E90FF; /* Change heading color to blue */
}

.feature p {
  color: #555;
}

/* TRY NOW Button */
.try-now-button {
  background-color: #1E90FF; /* Change button color to blue */
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 40px auto;
  display: block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.try-now-button:hover {
  background-color: #1C86EE; /* Slightly darker blue on hover */
}

/* Footer */
.footer {
  background: #ffffff; /* Change footer background to white */
  color: #777;
  text-align: center;
  padding: 20px 0;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  margin-top: auto;
}

.footer p {
  font-size: 1em;
}

.footer-link {
  color: #1E90FF;
  text-decoration: underline;
  margin-left: 5px;
}

.footer-link:hover {
  color: #1C86EE;
}

/* Responsive design */
@media (max-width: 768px) {
  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    max-width: 100%;
  }

  .try-now-button {
    width: 80%;
  }
}

/* src/Home.css */

/* ... existing styles ... */

/* Catchphrase Section */
.catchphrase {
  text-align: center;
  margin: 4px 2px;
}

.catchphrase p {
  font-size: 0.9em;
  color: #484d53;
}

.catchphrase a {
  color: #1E90FF;
  text-decoration: underline;
}

.catchphrase a:hover {
  color: #1C86EE;
}

.header {
  background-color: #ffffff;
  color: #1E90FF;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* To help center the content */
  display: flex;
  justify-content: center; /* Centers the text, ignores the icon */
}

.header-content {
  display: flex;
  align-items: center; /* Aligns the icon and text vertically */
}

.logo-icon {
  width: 84px; /* Adjust size */
  height: 84px;
  margin-right: 20px; /* Adds space between icon and text */
}

/* Centering the text without moving the icon */
.header-content h1 {
  font-size: 2.5em;
  margin: 0;
  text-align: center; /* Ensures the text is centered */
}